import React from 'react';
import { SingleASTNode } from 'simple-markdown';
import styled from 'styled-components';

import { useMarkdownParsing } from 'hooks';

interface TextProps {
    children: string | React.ReactElement[];
}

interface Props {
    children: React.ReactElement<TextProps>;
    className?: string;
}

export function RichText({ children, className }: Props) {
    const parse = useMarkdownParsing();

    function recursivelyRenderChildren(tree: SingleASTNode[], type?: string): React.ReactElement[] {
        return tree.map((node, i) => {
            if (type === 'strong' || type === 'em' || type === 'u') {
                if (Array.isArray(node.content)) {
                    return React.createElement(
                        type,
                        { key: `rich-text-${i}` },
                        recursivelyRenderChildren(node.content, node.type)
                    );
                }

                return React.createElement(type, { key: `rich-text-${i}` }, node.content);
            }

            if (Array.isArray(node.content)) {
                return recursivelyRenderChildren(node.content, node.type);
            }

            return node.content;
        });
    }

    const enhancedChildren = React.Children.map(children, (child) => {
        const syntaxTree = parse(child.props.children as string);
        return React.cloneElement(child, { children: recursivelyRenderChildren(syntaxTree) });
    });

    return <Container className={className}>{enhancedChildren}</Container>;
}

const Container = styled.span`
    color: inherit;
`;
