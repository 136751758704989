/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useSpring } from 'react-spring';

import { AnimatedContainer, Content, Overlay } from './BaseModal.style';

export interface BaseModalProps {
    open: boolean;
    onClose: () => void;
}

interface Props extends BaseModalProps {
    children: React.ReactElement;
    fullScreen?: boolean;
}

export function BaseModal({ open, children, fullScreen = false, onClose }: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const [rendered, setRendered] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (open) {
                setRendered(true);
            } else {
                setVisible(false);
            }
        }

        return () => {
            mounted = false;
        };
    }, [open]);

    useEffect(() => {
        let mounted = true;
        let timeout: number;

        if (rendered) {
            timeout = setTimeout(() => {
                if (mounted) {
                    setVisible(true);
                }
            }, 100);
        }

        return () => {
            mounted = false;
            clearTimeout(timeout);
        };
    }, [rendered]);

    useEffect(() => {
        let mounted = true;
        let timeout: number;

        if (!visible) {
            timeout = setTimeout(() => {
                if (mounted) {
                    setRendered(false);
                }
            }, 350);
        }

        return () => {
            mounted = false;
            clearTimeout(timeout);
        };
    }, [visible]);

    function handleClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            setVisible(false);
            onClose();
        }
    }

    const style = useSpring({
        opacity: visible ? 1 : 0,
        top: visible ? 0 : -20,
        config: {
            duration: 300
        }
    });

    if (!rendered) {
        return null;
    }

    return ReactDOM.createPortal(
        <FocusLock autoFocus returnFocus>
            <RemoveScroll>
                <Overlay fullScreen={fullScreen} onClick={handleClose}>
                    {fullScreen ? (
                        children
                    ) : (
                        <AnimatedContainer ref={ref} style={style}>
                            <Content>{children}</Content>
                        </AnimatedContainer>
                    )}
                </Overlay>
            </RemoveScroll>
        </FocusLock>,
        document.body
    );
}
