import { useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useEffectOnce } from 'hooks/utils';

export function useMeasure<T extends HTMLElement>(): [React.RefObject<T>, ClientRect] {
    const ref = useRef<T>(null);

    const [bounds, set] = useState({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0
    });
    const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));

    useEffectOnce(() => {
        if (ref.current) {
            ro.observe(ref.current);
        }

        return () => ro.disconnect();
    });

    return [ref, bounds];
}
