import React, { useState } from 'react';

import { links } from 'data/header.json';
import { logo } from 'data/landing.json';
import { buttons } from 'data/general.json';
import { headerDesktopToContactPage } from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useScrollPosition } from 'hooks';
import { PrimaryButton } from 'components/ui';

import { MobileMenu } from './MobileMenu';
import { Container, Image, ImageLink, Link, Menu, Nav, Wrapper } from './Header.style';

export function Header() {
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    const [isHeaderHidden, setIsHeaderHidden] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useScrollPosition(
        ({ previous, current }) => {
            if (current.y > 60) {
                if (previous.y < current.y && !isHeaderHidden && !isMenuOpen) {
                    setIsHeaderHidden(true);
                }
            }

            if (previous.y > current.y && isHeaderHidden) {
                setIsHeaderHidden(false);
            }
        },
        [isHeaderHidden, isMenuOpen]
    );

    function handleMenuOpen() {
        setIsMenuOpen(true);
    }

    function handleMenuClose() {
        setIsMenuOpen(false);
    }

    return (
        <Wrapper
            style={{
                transform: isHeaderHidden ? 'translate3d(0, -120px, 0)' : 'translate3d(0, 0, 0)'
            }}
        >
            <Container>
                <ImageLink to="/">
                    <Image src={`/images/${logo.src}`} alt={logo.alt} />
                </ImageLink>
                {isDesktop && (
                    <Nav>
                        <Link to="/" activeClassName="active-link">
                            {links.home}
                        </Link>
                        <Link to="/about-us/" activeClassName="active-link">
                            {links.about}
                        </Link>
                        <Link to="/services/" activeClassName="active-link">
                            {links.services}
                        </Link>
                        <Link partiallyActive to="/portfolio/" activeClassName="active-link">
                            {links.portfolio}
                        </Link>
                        <Link partiallyActive to="/careers/" activeClassName="active-link">
                            {links.careers}
                        </Link>
                        <Link partiallyActive to="/blog/" activeClassName="active-link">
                            {links.blog}
                        </Link>
                        <PrimaryButton to="/contact/" name={headerDesktopToContactPage}>
                            {buttons.contactCTA}
                        </PrimaryButton>
                    </Nav>
                )}
                {(isPhone || isTablet) && (
                    <Nav>
                        <Menu onClick={handleMenuOpen} />
                    </Nav>
                )}
            </Container>
            {(isPhone || isTablet) && <MobileMenu isOpen={isMenuOpen} onClose={handleMenuClose} />}
        </Wrapper>
    );
}
