import { useContext } from 'react';
import { QueryContext } from 'components/context';

export function useQueryContext() {
    const context = useContext(QueryContext);

    if (!context) {
        throw new Error('useQueryContext must be used within a QueryContext.Provider');
    }

    return context;
}
