import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';

import {
    AllImageSharp,
    AllMarkdownRemark,
    JobListItem,
    PostListItem,
    ResponsiveImage,
    SiteData
} from 'types';
import {
    NullInitResponsiveImage,
    processImages,
    processJobs,
    processPosts
} from './ContentProvider.helpers';

interface ContentQueryData {
    images: AllImageSharp;
    jobs: AllMarkdownRemark;
    posts: AllMarkdownRemark;
    site: SiteData;
}

interface QueryContextData {
    images: {
        landing: {
            hero: ResponsiveImage;
            ourWork: {
                image: ResponsiveImage;
            };
            industries: {
                image: ResponsiveImage;
            };
            ourTechnology: {
                image: ResponsiveImage;
            };

            testimonials: {
                image: ResponsiveImage;
            };
            jobProposal: {
                image: ResponsiveImage;
            };
            scheduleAppointment: {
                image: ResponsiveImage;
            };
        };
        aboutUs: {
            hero: ResponsiveImage;
            ourValues: {
                image: ResponsiveImage;
            };
            whatDrivesUs: {
                image: ResponsiveImage;
            };
            meetTheTeam: {
                waves: ResponsiveImage;
            };
        };
        services: {
            hero: ResponsiveImage;
            successScenarios: {
                waves: ResponsiveImage;
            };
            ourProcess: {
                background: ResponsiveImage;
            };
            expertise: {
                waves: ResponsiveImage;
            };
        };
        portfolio: {
            hero: ResponsiveImage;
            image: ResponsiveImage;
        };
        careers: {
            hero: ResponsiveImage;
            benefits: {
                image: ResponsiveImage;
            };
        };
        positions: {
            image: ResponsiveImage;
        };
        contact: {
            hero: ResponsiveImage;
        };
        blog: {
            image: ResponsiveImage;
        };

        whyWorkWithUs: {
            hero: ResponsiveImage;
            endToEnd: {
                image: ResponsiveImage;
            };
            award: {
                image: ResponsiveImage;
            };
        };

        automotiveSolutions: {
            hero: ResponsiveImage;
        };
    };
    site: SiteData;
    jobs: JobListItem[];
    posts: PostListItem[];
}

export const QueryContext = React.createContext<QueryContextData>({
    images: {
        landing: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            },
            ourWork: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            industries: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            ourTechnology: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            testimonials: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            jobProposal: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            scheduleAppointment: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            }
        },
        aboutUs: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            },
            ourValues: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            whatDrivesUs: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            meetTheTeam: {
                waves: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            }
        },
        services: {
            hero: { ...NullInitResponsiveImage },
            successScenarios: {
                waves: { ...NullInitResponsiveImage }
            },
            ourProcess: {
                background: { ...NullInitResponsiveImage }
            },
            expertise: {
                waves: { ...NullInitResponsiveImage }
            }
        },
        portfolio: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            },
            image: {
                mobile: null,
                tablet: null,
                desktop: null
            }
        },
        positions: {
            image: {
                mobile: null,
                tablet: null,
                desktop: null
            }
        },
        careers: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            },
            benefits: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            }
        },
        contact: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            }
        },
        blog: {
            image: {
                mobile: null,
                tablet: null,
                desktop: null
            }
        },
        whyWorkWithUs: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            },
            endToEnd: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            },
            award: {
                image: {
                    mobile: null,
                    tablet: null,
                    desktop: null
                }
            }
        },
        automotiveSolutions: {
            hero: {
                mobile: null,
                tablet: null,
                desktop: null
            }
        }
    },
    site: {
        siteMetadata: {
            title: '',
            author: '',
            description: '',
            language: '',
            siteUrl: ''
        }
    },
    jobs: [],
    posts: []
});

interface Props {
    children: React.ReactNode;
}

export function ContentProvider({ children }: Props) {
    const {
        images: rawImages,
        jobs: rawJobs,
        posts: rawPosts,
        site
    } = useStaticQuery<ContentQueryData>(CONTENT_QUERY);

    const images = useMemo(() => processImages(rawImages.edges), [rawImages]);
    const jobs = useMemo(() => processJobs(rawJobs.edges), [rawJobs]);
    const posts = useMemo(() => processPosts(rawPosts.edges), [rawPosts]);

    return (
        <QueryContext.Provider
            value={{
                images,
                jobs,
                posts,
                site
            }}
        >
            {children}
        </QueryContext.Provider>
    );
}

const CONTENT_QUERY = graphql`
    query {
        site {
            siteMetadata {
                title
                author
                description
                language
                siteUrl
            }
        }
        images: allImageSharp {
            edges {
                node {
                    fluid(quality: 85, maxWidth: 3840) {
                        originalName
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        jobs: allMarkdownRemark(filter: { frontmatter: { entity: { eq: "job" } } }, limit: 2000) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        slug
                        excerpt
                        isAvailable
                        featuredImage {
                            childImageSharp {
                                fluid(quality: 85, maxWidth: 3840) {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        posts: allMarkdownRemark(
            filter: { frontmatter: { entity: { eq: "post" } } }
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        slug
                        featuredImage {
                            childImageSharp {
                                fluid(quality: 85, maxWidth: 3840) {
                                    originalName
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        featuredImage_alt
                        date
                        author
                        category
                        timeToRead
                        metaDescription
                    }
                }
            }
        }
    }
`;
