import React from 'react';
import styled from 'styled-components';

import { Colors } from 'environment/theme';
import { BaseButton, BaseProps } from './BaseButton';

export function PrimaryButton(props: BaseProps) {
    return <Button {...props} backgroundColor={Colors.new.green} color={Colors.white} />;
}

const Button = styled(BaseButton)`
    :not(:disabled):hover {
        background-color: ${Colors.new.greenHover};
    }

    :disabled {
        background-color: ${Colors.new.lightGreenDisabled};
        color: ${Colors.new.greenDisabled};
        opacity: 1;
    }
`;
