export function throttle<T extends (...args: any[]) => void>(
    func: T,
    threshold = 250,
    scope?: any
): T {
    let last: number, deferTimer: number;

    return function (this: any, ...args) {
        const context = scope || this;

        const now = Date.now();
        if (last && now < last + threshold) {
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                func.apply(context, [...args]);
            }, threshold);
        } else {
            last = now;
            func.apply(context, [...args]);
        }
    } as T;
}

export function computeBezierCurve(
    sx: number,
    sy: number,
    ex: number,
    ey: number,
    reverse = false
) {
    const mx = (sx + ex) * 0.5;
    const my = (sy + ey) * 0.5;

    const theta = Math.atan2(ey - sy, ex - sx) - Math.PI / 2;
    const offset = reverse ? -55 : 55;

    const cx = mx + offset * Math.cos(theta);
    const cy = my + offset * Math.sin(theta);

    return `M${sx} ${sy} Q${cx} ${cy} ${ex} ${ey}`;
}

export function sendEmail(emailEncoded: string, subject: string) {
    const decodedEmailAddress = atob(emailEncoded);
    window.location.href = `mailto:${decodedEmailAddress}?subject=${subject || ''}`;
}
