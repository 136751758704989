import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';
import { ImageSharpNode, Nullable } from 'types';

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
`;

interface ImageProps {
    scaleX?: number;
    position?: string;
    minHeight?: number;
    scaleTablet?: number;
    zIndex?: number;
}

const Image = styled(Img)<ImageProps>`
    pointer-events: none;
    position: ${({ position }) => position || 'absolute !important'};
    user-select: none;
    width: 100%;
    z-index: ${({ zIndex }) => zIndex};
    min-height: ${({ minHeight }) => minHeight && `${minHeight}rem`};

    @media ${MediaQueries.desktop} {
        transform: ${({ scaleX }) => scaleX && `scaleX(${scaleX})`};
    }

    @media ${MediaQueries.tablet} {
        transform: ${({ scaleTablet }) => scaleTablet && `scale(${scaleTablet})`};
    }
`;

const NetlifyImage = styled.img<ImageProps>`
    pointer-events: none;
    position: ${({ position }) => position || 'absolute !important'};
    user-select: none;
    width: 100%;
    z-index: 999;

    @media ${MediaQueries.desktop} {
        transform: ${({ scaleX }) => scaleX && `scaleX(${scaleX})`};
    }

    @media ${MediaQueries.tablet} {
        transform: ${({ scaleTablet }) => scaleTablet && `scale(${scaleTablet})`};
    }
    @media ${MediaQueries.phone} {
        min-height: ${({ minHeight }) => minHeight && `${minHeight}rem`};
    }
`;

interface Props {
    children?: React.ReactNode;
    scaleX?: number;
    position?: string;
    minHeight?: number;
    scaleTablet?: number;
    mobile?: Nullable<ImageSharpNode>;
    tablet?: Nullable<ImageSharpNode>;
    desktop?: Nullable<ImageSharpNode>;
    alt?: string;
    netlifyImage?: string;
    className?: string;
    zIndex?: number;
}

export function BackgroundImage({
    children,
    scaleX,
    position,
    minHeight,
    scaleTablet,
    mobile,
    tablet,
    desktop,
    netlifyImage = '',
    alt,
    className,
    zIndex = -1
}: Props) {
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    return (
        <Container className={className}>
            {children}
            {!netlifyImage && (
                <>
                    {isPhone && mobile && (
                        <Image
                            fluid={mobile.fluid}
                            alt={alt}
                            scaleX={scaleX}
                            position={position}
                            minHeight={minHeight}
                            zIndex={zIndex}
                        />
                    )}
                    {isTablet && tablet && (
                        <Image
                            fluid={tablet.fluid}
                            alt={alt}
                            scaleX={scaleX}
                            position={position}
                            scaleTablet={scaleTablet}
                            minHeight={minHeight}
                            zIndex={zIndex}
                        />
                    )}
                    {isDesktop && desktop && (
                        <Image
                            fluid={desktop.fluid}
                            alt={alt}
                            scaleX={scaleX}
                            position={position}
                            minHeight={minHeight}
                            zIndex={zIndex}
                        />
                    )}
                </>
            )}
            {netlifyImage && <NetlifyImage src={netlifyImage} />}
        </Container>
    );
}
