import { useState } from 'react';

import { WINDOW_EXISTS } from 'consts';
import { throttle } from 'helpers';
import { useEffectOnce } from 'hooks/utils';

const events = new Set<() => void>();
const onResize = () => events.forEach((fn) => fn());

export function useWindowSize(delay = 100) {
    const [size, setSize] = useState({
        width: WINDOW_EXISTS ? window.innerWidth : 0,
        height: WINDOW_EXISTS ? window.innerHeight : 0
    });

    const handle = throttle(() => {
        if (WINDOW_EXISTS) {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
    }, delay);

    useEffectOnce(() => {
        if (WINDOW_EXISTS) {
            if (events.size === 0) {
                window.addEventListener('resize', onResize, true);
            }

            events.add(handle);
        }

        return () => {
            if (WINDOW_EXISTS) {
                events.delete(handle);

                if (events.size === 0) {
                    window.removeEventListener('resize', onResize, true);
                }
            }
        };
    });

    return size;
}
