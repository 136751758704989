import { animated } from 'react-spring';
import styled from 'styled-components';
import { Link as DefaultLink } from 'gatsby';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

import { PrimaryButton } from '../Button';

const Container = styled.div`
    background-color: ${Colors.violet};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 12rem;
    position: fixed;
    width: 101%;
    z-index: 999;
    bottom: 0;

    @media ${MediaQueries.phone} {
        height: auto;
        width: 100%;
    }
`;
export const AnimatedContainer = animated(Container);

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    @media ${MediaQueries.phone} {
        padding: 2rem;
        flex-direction: column;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        align-items: center;
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
        padding: 0 2rem;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex: 1;

    @media ${MediaQueries.phone} {
        margin-bottom: 2.4rem;
    }
`;

export const Text = styled.p`
    color: ${Colors.dirtyWhite};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-left: 2rem;

    @media ${MediaQueries.tablet} {
        width: 22rem;
        margin-left: 0rem;
    }
`;

export const Link = styled(DefaultLink)`
    font-size: 1.4rem;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.green};
    transition: border-color 0.2s;

    &:hover {
        border-color: ${Colors.green};
    }
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;

    @media ${MediaQueries.tablet} {
        margin-left: 2rem;
    }

    @media ${MediaQueries.desktop} {
        margin-left: 2rem;
    }
`;

export const Button = styled(PrimaryButton)`
    height: 4rem;
    min-width: 18rem;

    :first-of-type {
        justify-content: flex-start;
        padding-left: 0rem;

        @media ${MediaQueries.tablet} {
            min-width: 12rem;
            padding: 0;
        }

        @media ${MediaQueries.phone} {
            min-width: 15.5rem;
            padding: 0;
        }
    }

    :last-of-type {
        margin-left: 0rem;
        height: 5rem;

        @media ${MediaQueries.desktop} {
            margin-left: 2;
        }
    }
`;
