import styled from 'styled-components';
import { Colors } from 'environment';

interface Props {
    error?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const Input = styled.input<Props>`
    width: 100%;
    height: 6rem;
    font-family: 'Rubik';
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding: 0 2rem;
    color: ${Colors.violet};
    background-color: transparent;
    border: none;
    border-bottom: ${({ error }) => `0.1rem ${error ? Colors.danger : Colors.brownGrey} solid`};
    border-radius: 0;
    outline: none;
    transition: border-color 0.2s;

    ::placeholder {
        color: ${Colors.lavender};
    }

    :focus,
    :active {
        border-color: ${({ error }) => (error ? Colors.danger : Colors.violet)};
    }

    :hover {
        border-color: ${({ error }) => (error ? Colors.danger : Colors.violet)};
    }
`;

export const Textarea = styled.textarea<Props>`
    display: flex;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 6rem;
    height: ${({ rows }) => (rows ? 'unset' : '6rem')};
    font-family: 'Rubik';
    font-size: 1.8rem;
    padding: 2rem;
    color: ${Colors.violet};
    background-color: transparent;
    border: none;
    border-bottom: ${({ error }) => `0.1rem ${error ? Colors.danger : Colors.brownGrey} solid`};
    border-radius: 0;
    overflow: hidden;
    outline: none;
    transition: border-color 0.2s;

    ::placeholder {
        color: ${Colors.lavender};
    }

    :focus,
    :active {
        border-color: ${({ error }) => (error ? Colors.danger : Colors.violet)};
    }
`;

export const Error = styled.span`
    position: absolute;
    color: ${Colors.danger};
    bottom: -2.5rem;
`;
