import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Cookies from 'js-cookie';

import { PAGE_UNLOADED_WHY_WORK_WITH_US, PAGE_LOADED_WHY_WORK_WITH_US } from 'consts';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { CookieConsent } from '../CookieConsent';

import 'environment/theme/base.scss';
import 'prismjs/themes/prism.css';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Content = styled.main<{ headerAndFooterExcluded: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${(props) =>
        !props.headerAndFooterExcluded &&
        css`
            top: 10rem;
        `}
    height: 100%;
    min-height: calc(100vh - 70rem);
    width: 100%;
    overflow: hidden;
`;

const PAGES_WITH_EXCLUDED_HEADER_AND_FOOTER = [
    '/why-work-with-us/',
    '/solutii-software-personalizate-automotive/'
];

interface Props {
    children: React.ReactNode;
}

/**
 * Because Layout is the first element under the QueryContext.Provider,
 * it should be memoized to stop propagating child updates when the
 * context value changes.
 */
export const Layout = React.memo(function ({ children }: Props) {
    const [cookieProcessed, setCookieProcessed] = useState(!!Cookies.get('cookie-consent'));
    const [shouldExcludeHeaderAndFooter, setShouldExcludeHeaderAndFooter] = useState(false);

    function onCookieProcessedUpdate() {
        setCookieProcessed(true);
    }

    function updateExcludeHeaderAndFooter() {
        const pathname = window.location.pathname;
        setShouldExcludeHeaderAndFooter(
            PAGES_WITH_EXCLUDED_HEADER_AND_FOOTER.indexOf(pathname) >= 0
        );
    }

    useEffect(() => {
        updateExcludeHeaderAndFooter();

        document.addEventListener(PAGE_LOADED_WHY_WORK_WITH_US, updateExcludeHeaderAndFooter);
        document.addEventListener(PAGE_UNLOADED_WHY_WORK_WITH_US, updateExcludeHeaderAndFooter);

        return () => {
            document.removeEventListener(
                PAGE_LOADED_WHY_WORK_WITH_US,
                updateExcludeHeaderAndFooter
            );
            document.removeEventListener(
                PAGE_UNLOADED_WHY_WORK_WITH_US,
                updateExcludeHeaderAndFooter
            );
        };
    }, []);

    return (
        <Container>
            {!shouldExcludeHeaderAndFooter && <Header />}
            <Content headerAndFooterExcluded={shouldExcludeHeaderAndFooter}>{children}</Content>
            {!shouldExcludeHeaderAndFooter && <Footer />}
            {/* We no longer want Calendly integration since we have HubSpot chat bubble */}
            {/*<ScheduleAppointment cookieProcessed={cookieProcessed} />*/}
            <CookieConsent onCookieProcessedUpdate={onCookieProcessedUpdate} />
        </Container>
    );
});
