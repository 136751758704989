import { Link as BaseLink } from 'gatsby';
import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes, Icons } from 'environment';

export const Wrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${Colors.white};
    z-index: 10;
    transition: transform 0.3s;
`;

export const Container = styled.header`
    display: flex;
    align-items: center;
    height: 10rem;
    width: 100%;
    text-transform: uppercase;

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}px) {
        padding: 0 2rem;
    }
`;

export const ImageLink = styled(BaseLink)`
    height: 4rem;
`;

export const Image = styled.img`
    height: 4rem;
`;

export const Nav = styled.nav`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;
`;

export const Link = styled(BaseLink)`
    display: flex;
    justify-content: center;

    position: relative;
    color: ${Colors.violet};
    font-weight: 400;
    text-align: center;
    transition: color 0.2s;
    width: 13rem;
    height: 100%;

    &.active-link {
        color: ${Colors.magenta};
        font-weight: 500;
    }

    &.active-link::after {
        content: '';
        height: 0.8rem;
        width: 0.8rem;
        position: absolute;
        top: calc(6rem - 0.8rem);
        left: calc(6.5rem - 0.4rem);
        background-color: ${Colors.magenta};
        border-radius: 50%;
    }

    &:last-child {
        align-items: center;
        color: ${Colors.violet};
        background-color: ${Colors.lightGray};
        border-radius: 2.5rem;
        font-weight: 500;
        width: 18rem;
        height: 5rem;
    }

    &:last-child::after {
        display: none;
    }
`;

export const Menu = styled(Icons.Menu)`
    height: 4rem;
`;
