import ArrowLeft from '../../../static/images/arrow-left.svg';
import ArrowRight from '../../../static/images/arrow-right.svg';
import CirclesLeft from '../../../static/images/circles-left.svg';
import CirclesRight from '../../../static/images/circles-right.svg';
import CirclesRight2 from '../../../static/images/circles-right-2.svg';
import CirclesLeft2 from '../../../static/images/circles-left-2.svg';
import CirclesRightTablet2 from '../../../static/images/circles-right-2-tablet.svg';
import Close from '../../../static/images/close.svg';
import Waves from '../../../static/images/waves.svg';
import SensidevLogo from '../../../static/images/footer-logo.svg';
import DeleteFile from '../../../static/images/delete-file.svg';
import Facebook from '../../../static/images/contact-facebook.svg';
import Instagram from '../../../static/images/contact-instagram.svg';
import LinkedIn from '../../../static/images/contact-linkedin.svg';
import FooterLogo from '../../../static/images/footer-logo.svg';
import EmailIcon from '../../../static/images/email-logo.svg';
import LocationIcon from '../../../static/images/location-logo.svg';
import Menu from '../../../static/images/menu.svg';
import ExpertiseCircles from '../../../static/images/services/expertise-top-right-circles.svg';

export const Icons = {
    ArrowLeft,
    ArrowRight,
    Close,
    DeleteFile,
    SensidevLogo,
    Facebook,
    Instagram,
    LinkedIn,
    CirclesLeft,
    CirclesLeft2,
    CirclesRight,
    CirclesRight2,
    CirclesRightTablet2,
    Waves,
    FooterLogo,
    EmailIcon,
    LocationIcon,
    Menu,
    ExpertiseCircles
};
