import {
    ImageSharpEdge,
    MarkdownRemarkEdge,
    ResponsiveImage,
    JobListItem,
    ImageSharpNode
} from 'types';
import landingContent from 'data/landing.json';
import aboutUsContent from 'data/about-us.json';
import servicesContent from 'data/services.json';
import portfolioContent from 'data/portfolio.json';
import careersContent from 'data/careers.json';
import contactContent from 'data/contact.json';
import blogBackgroundImage from 'data/blog.json';
import whyWorkWithUs from 'data/why-work-with-us.json';
import automotiveSolutions from 'data/solutii-automotive.json';

export const NullInitResponsiveImage: ResponsiveImage = {
    desktop: null,
    tablet: null,
    mobile: null
};

interface ResponsiveImagePaths {
    desktop: string;
    tablet: string;
    mobile: string;
}

function initEdgesFor(
    edgeNode: ImageSharpNode,
    object: ResponsiveImage,
    resImage: ResponsiveImagePaths
) {
    if (edgeNode.fluid.originalName === resImage.mobile) {
        object.mobile = edgeNode;
    } else if (edgeNode.fluid.originalName === resImage.tablet) {
        object.tablet = edgeNode;
    } else if (edgeNode.fluid.originalName === resImage.desktop) {
        object.desktop = edgeNode;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function processImages(edges: ImageSharpEdge[]) {
    const landingHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const landingOurWork: ResponsiveImage = { ...NullInitResponsiveImage };
    const landingIndustries: ResponsiveImage = { ...NullInitResponsiveImage };

    const landingOurTechnology: ResponsiveImage = { ...NullInitResponsiveImage };

    const landingTestimonials: ResponsiveImage = { ...NullInitResponsiveImage };

    const landingJobProposal: ResponsiveImage = { ...NullInitResponsiveImage };

    const aboutUsHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const aboutUsWhatDrivesUs: ResponsiveImage = { ...NullInitResponsiveImage };

    const aboutOurValues: ResponsiveImage = { ...NullInitResponsiveImage };

    const meetTheTeamWaves: ResponsiveImage = { ...NullInitResponsiveImage };

    const servicesHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const portfolioHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const portfolioImage: ResponsiveImage = { ...NullInitResponsiveImage };

    const contactHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const careersHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const careersBenefits: ResponsiveImage = { ...NullInitResponsiveImage };

    const positionsCTA: ResponsiveImage = { ...NullInitResponsiveImage };

    const blog: ResponsiveImage = { ...NullInitResponsiveImage };

    const scheduleAppointmentAvatar: ResponsiveImage = { ...NullInitResponsiveImage };

    const whyWorkWithUsHero: ResponsiveImage = { ...NullInitResponsiveImage };

    const endToEnd: ResponsiveImage = { ...NullInitResponsiveImage };

    const award: ResponsiveImage = { ...NullInitResponsiveImage };

    const servicesScenariosWaves: ResponsiveImage = { ...NullInitResponsiveImage };

    const servicesOurProcessBackground: ResponsiveImage = { ...NullInitResponsiveImage };

    const servicesExpertiseWaves: ResponsiveImage = { ...NullInitResponsiveImage };

    const automotiveSolutionsHero: ResponsiveImage = { ...NullInitResponsiveImage };

    edges.forEach((edge) => {
        if (edge.node.fluid.originalName === landingContent.hero.image.mobile) {
            landingHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.hero.image.tablet) {
            landingHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.hero.image.desktop) {
            landingHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.ourWork.image.mobile) {
            landingOurWork.mobile = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.ourWork.image.tablet) {
            landingOurWork.tablet = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.ourWork.image.desktop) {
            landingOurWork.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.industries.image.mobile) {
            landingIndustries.mobile = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.industries.image.tablet) {
            landingIndustries.tablet = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.industries.image.desktop) {
            landingIndustries.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.ourTechnologyStack.image.mobile) {
            landingOurTechnology.mobile = edge.node;
        } else if (
            edge.node.fluid.originalName === landingContent.ourTechnologyStack.image.tablet
        ) {
            landingOurTechnology.tablet = edge.node;
        } else if (
            edge.node.fluid.originalName === landingContent.ourTechnologyStack.image.desktop
        ) {
            landingOurTechnology.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.testimonials.image.mobile) {
            landingTestimonials.mobile = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.testimonials.image.tablet) {
            landingTestimonials.tablet = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.testimonials.image.desktop) {
            landingTestimonials.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.jobProposal.image.mobile) {
            landingJobProposal.mobile = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.jobProposal.image.tablet) {
            landingJobProposal.tablet = edge.node;
        } else if (edge.node.fluid.originalName === landingContent.jobProposal.image.desktop) {
            landingJobProposal.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === aboutUsContent.hero.image.mobile) {
            aboutUsHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.hero.image.tablet) {
            aboutUsHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.hero.image.desktop) {
            aboutUsHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === aboutUsContent.whatDrivesUs.image.mobile) {
            aboutUsWhatDrivesUs.mobile = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.whatDrivesUs.image.tablet) {
            aboutUsWhatDrivesUs.tablet = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.whatDrivesUs.image.desktop) {
            aboutUsWhatDrivesUs.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === aboutUsContent.ourValues.image.mobile) {
            aboutOurValues.mobile = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.ourValues.image.tablet) {
            aboutOurValues.tablet = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.ourValues.image.desktop) {
            aboutOurValues.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === servicesContent.hero.image.mobile) {
            servicesHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === servicesContent.hero.image.tablet) {
            servicesHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === servicesContent.hero.image.desktop) {
            servicesHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === portfolioContent.hero.image.mobile) {
            portfolioHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === portfolioContent.hero.image.tablet) {
            portfolioHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === portfolioContent.hero.image.desktop) {
            portfolioHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === portfolioContent.image.mobile) {
            portfolioImage.mobile = edge.node;
        } else if (edge.node.fluid.originalName === portfolioContent.image.tablet) {
            portfolioImage.tablet = edge.node;
        } else if (edge.node.fluid.originalName === portfolioContent.image.desktop) {
            portfolioImage.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === aboutUsContent.meetTheTeam.waves.image.mobile) {
            meetTheTeamWaves.mobile = edge.node;
        } else if (edge.node.fluid.originalName === aboutUsContent.meetTheTeam.waves.image.tablet) {
            meetTheTeamWaves.tablet = edge.node;
        } else if (
            edge.node.fluid.originalName === aboutUsContent.meetTheTeam.waves.image.desktop
        ) {
            meetTheTeamWaves.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === careersContent.hero.image.mobile) {
            careersHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.hero.image.tablet) {
            careersHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.hero.image.desktop) {
            careersHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === careersContent.benefits.image.mobile) {
            careersBenefits.mobile = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.benefits.image.tablet) {
            careersBenefits.tablet = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.benefits.image.desktop) {
            careersBenefits.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === careersContent.positions.image.mobile) {
            positionsCTA.mobile = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.positions.image.tablet) {
            positionsCTA.tablet = edge.node;
        } else if (edge.node.fluid.originalName === careersContent.positions.image.desktop) {
            positionsCTA.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === contactContent.image.mobile) {
            contactHero.mobile = edge.node;
        } else if (edge.node.fluid.originalName === contactContent.image.tablet) {
            contactHero.tablet = edge.node;
        } else if (edge.node.fluid.originalName === contactContent.image.desktop) {
            contactHero.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === blogBackgroundImage.image.mobile) {
            blog.mobile = edge.node;
        } else if (edge.node.fluid.originalName === blogBackgroundImage.image.tablet) {
            blog.tablet = edge.node;
        } else if (edge.node.fluid.originalName === blogBackgroundImage.image.desktop) {
            blog.desktop = edge.node;
        }

        if (edge.node.fluid.originalName === landingContent.scheduleAppointment.image.mobile) {
            scheduleAppointmentAvatar.mobile = edge.node;
        } else if (
            edge.node.fluid.originalName === landingContent.scheduleAppointment.image.tablet
        ) {
            scheduleAppointmentAvatar.tablet = edge.node;
        } else if (
            edge.node.fluid.originalName === landingContent.scheduleAppointment.image.desktop
        ) {
            scheduleAppointmentAvatar.desktop = edge.node;
        }

        initEdgesFor(edge.node, whyWorkWithUsHero, whyWorkWithUs.hero.image);
        initEdgesFor(edge.node, endToEnd, whyWorkWithUs.endToEndServices.image);
        initEdgesFor(edge.node, award, whyWorkWithUs.award.image);

        initEdgesFor(edge.node, automotiveSolutionsHero, automotiveSolutions.hero.image);

        initEdgesFor(edge.node, servicesScenariosWaves, servicesContent.successScenarios.waves);

        initEdgesFor(
            edge.node,
            servicesOurProcessBackground,
            servicesContent.ourProcess.background
        );

        initEdgesFor(edge.node, servicesExpertiseWaves, servicesContent.expertise.waves);
    });

    return {
        landing: {
            hero: landingHero,
            ourWork: {
                image: landingOurWork
            },
            industries: {
                image: landingIndustries
            },
            ourTechnology: {
                image: landingOurTechnology
            },
            testimonials: {
                image: landingTestimonials
            },
            jobProposal: {
                image: landingJobProposal
            },
            scheduleAppointment: {
                image: scheduleAppointmentAvatar
            }
        },
        aboutUs: {
            hero: aboutUsHero,
            ourValues: {
                image: aboutOurValues
            },
            whatDrivesUs: {
                image: aboutUsWhatDrivesUs
            },
            meetTheTeam: {
                waves: meetTheTeamWaves
            }
        },
        services: {
            hero: servicesHero,
            successScenarios: {
                waves: servicesScenariosWaves
            },
            ourProcess: {
                background: servicesOurProcessBackground
            },
            expertise: {
                waves: servicesExpertiseWaves
            }
        },
        portfolio: {
            hero: portfolioHero,
            image: portfolioImage
        },
        careers: {
            hero: careersHero,
            benefits: {
                image: careersBenefits
            }
        },
        positions: {
            image: positionsCTA
        },
        contact: {
            hero: contactHero
        },
        blog: {
            image: blog
        },

        whyWorkWithUs: {
            hero: whyWorkWithUsHero,
            endToEnd: {
                image: endToEnd
            },
            award: {
                image: award
            }
        },

        automotiveSolutions: {
            hero: automotiveSolutionsHero,
            //  TODO: update next two images!
            endToEnd: {
                image: endToEnd
            },
            award: {
                image: award
            }
        }
    };
}

export function processJobs(edges: MarkdownRemarkEdge[]) {
    const jobs: JobListItem[] = [];
    careersContent.jobs.forEach((slug) => {
        const edge = edges.find((d) => d.node.frontmatter.slug === slug);
        if (edge)
            jobs.push({
                id: edge.node.id,
                path: `/careers/${edge.node.frontmatter.slug}`,
                title: edge.node.frontmatter.title,
                featuredImage: edge.node.frontmatter.featuredImage,
                excerpt: edge.node.frontmatter.excerpt || '',
                isAvailable: edge.node.frontmatter.isAvailable || false,
                metaDescription: edge.node.frontmatter.metaDescription || ''
            });
    });
    return jobs;
}

export function processPosts(edges: MarkdownRemarkEdge[]) {
    return (
        edges
            .map((edge) => ({
                id: edge.node.id,
                path: `/blog/${edge.node.frontmatter.slug}`,
                featuredImage: edge.node.frontmatter.featuredImage,
                featuredImage_alt: edge.node.frontmatter.featuredImage_alt,
                title: edge.node.frontmatter.title,
                author: edge.node.frontmatter.author,
                date: edge.node.frontmatter.date,
                category: edge.node.frontmatter.category,
                slug: edge.node.frontmatter.slug,
                timeToRead: edge.node.frontmatter.timeToRead || 1,
                metaDescription: edge.node.frontmatter.metaDescription
            }))
            // We need to make sure blog articles are sorted, with most recent first
            .sort((edge1, edge2) => new Date(edge2.date).getTime() - new Date(edge1.date).getTime())
    );
}
