import { useEffect, useState } from 'react';
import { WINDOW_EXISTS } from 'consts';

export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(
        WINDOW_EXISTS ? window.matchMedia(query).matches : false
    );

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);
        let active = true;

        function listener() {
            if (!active) {
                return;
            }

            if (mediaQueryList.matches) {
                setMatches(true);
            } else {
                setMatches(false);
            }
        }

        mediaQueryList.addListener(listener);
        setMatches(mediaQueryList.matches);

        return () => {
            active = false;
            mediaQueryList.removeListener(listener);
        };
    }, [query]);

    return matches;
}
