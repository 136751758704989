export const PHONE_GRID_SIZE = 33.5;
export const TABLET_GRID_SIZE = 62;
export const DESKTOP_GRID_COLUMN = 8;
export const DESKTOP_GRID_SPACING = 2;
export const DESKTOP_GRID_SIZE = DESKTOP_GRID_COLUMN * 12 + DESKTOP_GRID_SPACING * 11;
export const DESKTOP_GRID_SIZE_NO_SPACING = DESKTOP_GRID_COLUMN * 12;
export const DOCUMENT_EXISTS = typeof document !== 'undefined';
export const WINDOW_EXISTS = typeof window !== 'undefined';
export const BLOG_POST_DATE_FORMAT = 'MM/dd/yyyy';
export const DEBOUNCE_TIME = 500;
export const PAGE_LOADED_WHY_WORK_WITH_US = 'PAGE_LOADED_WHY_WORK_WITH_US';
export const PAGE_UNLOADED_WHY_WORK_WITH_US = 'PAGE_UNLOADED_WHY_WORK_WITH_US';
