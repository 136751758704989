import React from 'react';
import Cookies from 'js-cookie';

import { ContentProvider } from './src/components/context';
import { Layout } from './src/components/ui';
import socialConfig from './src/data/social.json';
import { WINDOW_EXISTS } from './src/consts';

// DON'T MOVE THIS AT THE TOP! WON'T WORK CORRECT
import 'prismjs/themes/prism-twilight.css';
// import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

export const onClientEntry = () => {
    window[`ga-disable-${socialConfig.googleAnalyticsID}`] = false;
    const cookie = Cookies.get('cookie-consent');

    if (WINDOW_EXISTS) {
        if (cookie) {
            const consent = JSON.parse(cookie);

            if (!consent) {
                window[`ga-disable-${socialConfig.googleAnalyticsID}`] = true;
            }
        }
    }
};

export function wrapRootElement({ element }) {
    return (
        <ContentProvider>
            <Layout>{element}</Layout>
        </ContentProvider>
    );
}
