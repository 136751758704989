import React from 'react';
import styled from 'styled-components';

import { Colors } from 'environment/theme';
import { BaseButton, BaseProps } from './BaseButton';

export function SecondaryButton(props: BaseProps) {
    return <Button {...props} backgroundColor="transparent" color={Colors.white} />;
}

const Button = styled(BaseButton)`
    :not(:disabled):hover {
        color: ${Colors.new.lightGrayHover};
        border-color: ${Colors.new.lightGrayHover};
    }
`;
