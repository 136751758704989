import { animated } from 'react-spring';
import styled from 'styled-components';

import { TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

interface StyleProps {
    fullScreen?: boolean;
}

export const Overlay = styled.div<StyleProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: ${({ fullScreen }) =>
        fullScreen ? `${Colors.white}` : `${Colors.modalOverlay}`};
    overflow-y: ${({ fullScreen }) => (fullScreen ? 'auto' : 'unset')};
`;

const Container = styled.div`
    background-color: ${Colors.white};
    border-radius: 0.8rem;
    position: relative;
    max-width: calc(100% - 4rem);
    max-height: calc(100% - 4rem);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: 58rem;
    }
`;
export const AnimatedContainer = animated(Container);

export const Content = styled.div`
    padding: 4rem;

    @media ${MediaQueries.phone} {
        padding-left: 2rem;
        padding-right: 2rem;
    }
`;
