import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useMeasure } from 'hooks';

import { SliderProps } from './Slider';

const Container = styled.div`
    position: absolute;
    transition: all 0.4s ease-in-out;
`;

interface Props extends SliderProps {
    children: React.ReactNode;
}

export function SliderItem({ children, activeIndex = 0, index = 0, onMeasure }: Props) {
    const [ref, { height, width }] = useMeasure<HTMLDivElement>();

    useEffect(() => {
        if (height) {
            onMeasure && onMeasure(height);
        }
    }, [height]);

    return (
        <Container
            ref={ref}
            style={{
                opacity: activeIndex === index ? 1 : 0,
                transform: `translate3d(${
                    activeIndex === index ? 0 : activeIndex > index ? -width : width
                }px, 0, 0)`
            }}
        >
            {children}
        </Container>
    );
}
