import { useState } from 'react';

interface ModalProps {
    open: boolean;
    onClose: () => void;
}

export function useModal(defaultOpen = false): [ModalProps, () => void] {
    const [open, setOpen] = useState(defaultOpen);

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return [{ open, onClose: handleClose }, handleOpen];
}
