import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { RichText } from 'components/ui';
import { Icons } from 'environment';

import api from 'data/api.json';
import { jobsToMondayPositions } from 'data/careers.json';
import analytics from 'data/analytics.json';
import {
    cta,
    description,
    personalInformation,
    workInformation,
    successInformation,
    privacyPolicy,
    disclaimer
} from 'data/modal.json';

import { DropZone } from '../DropZone';
import { BaseModal, BaseModalProps } from './BaseModal';

import {
    Container,
    Content,
    ContentHeader,
    ContentHeaderDescription,
    ContentHeaderTitle,
    InputContainer,
    Error as UIError,
    FirstName,
    LastName,
    ModalHeader,
    CloseIcon,
    Name,
    BaitInput,
    PersonalInformation,
    PersonalInformationTitle,
    TextInput,
    Upload,
    UploadLabel,
    Work,
    WorkAddLink,
    WorkDescription,
    WorkInformation,
    WorkInformationTitle,
    Disclaimer,
    Link,
    AutoresizeInput,
    ExternalLink,
    PrimaryButton,
    AppliedSuccessfully,
    SeeOtherJobsButton
} from './ApplyModal.style';

interface Props extends BaseModalProps {
    title: string;
    job?: string;
}

const validationSchema = yup.object().shape({
    firstName: yup.string().required(personalInformation.form.firstName.required),
    lastName: yup.string().required(personalInformation.form.lastName.required),
    email: yup
        .string()
        .email(personalInformation.form.email.invalid)
        .required(personalInformation.form.email.required),
    phone: yup.string().required(personalInformation.form.phone.required),
    linkedIn: yup.string().required(workInformation.form.linkedIn.required),
    websites: yup.array().of(
        yup.string().matches(
            //prettier-ignore
            //eslint-disable-next-line
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig,
            workInformation.form.websites.message
        )
    ),
    startDate: yup.string(),
    desiredIncome: yup.string(),
    references: yup.string(),
    finalMessage: yup.string()
});

const CREATE_APPLICATION_MUTATION = `
    mutation CreateApplication($boardId: Int!, $name: String!, $columnValues: JSON!) {
        create_item(board_id: $boardId, item_name: $name, column_values: $columnValues) {
            id
            name
            column_values {
                id
                value
            }
        }
    }
`;

const UPLOAD_CV_MUTATION = `
    mutation UploadCV($itemId: Int!, $file: File!) {
        add_file_to_column(item_id: $itemId, column_id: "files", file: $file) {
            id
        }
    }
`;

interface FormValues {
    bait: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    linkedIn: string;
    websites: string[];
    startDate: string;
    desiredIncome: string;
    references: string;
    finalMessage: string;
}

interface CreateApplicationResponse {
    create_item: {
        id: string;
    };
}

const TODAY_DATE_STRING = new Date().toISOString().substr(0, 10); // quick way to get a date with format YYYY-MM-DD, as accepted by Monday.

export function ApplyModal({ onClose, title, job, ...props }: Props) {
    const [cvFiles, setCVFiles] = useState<File[]>([]); // only one will be stored here

    const [loading, setLoading] = useState(false); // used for both Sensix API and Monday GraphQL
    const [error, setError] = useState(false); // used just by Monday API
    const [finishedWithSuccess, setFinishedWithSuccess] = useState(false);

    async function sendCandidateApplicationToSensixAPI({
        firstName,
        lastName,
        email,
        phone,
        linkedIn,
        websites,
        startDate,
        desiredIncome,
        references,
        finalMessage
    }: FormValues) {
        try {
            // Obs: CV file is not sent to Sensix API, only to Monday API.
            const formData = new FormData();
            formData.append('source', `${api.contactSource}`);
            formData.append('name', `${firstName} ${lastName}`);
            formData.append('email', email);
            formData.append('phone', phone);

            const messageArray = [
                'LinkedIn: ' + linkedIn,
                'Links: \n' + websites.join('\n'),
                'Start date: ' + startDate,
                'Rate: ' + desiredIncome,
                'References: ' + references,
                'Candidate message: ' + finalMessage
            ];
            formData.append('message', messageArray.join('\n'));
            formData.append('key', api.contactKey);
            if (job) {
                formData.append('job', job);
            }

            const response = await fetch(api.contact, {
                method: 'post',
                body: formData
            });
            if (response.status !== 201 && response.status !== 200) {
                throw Error('Status error ' + response.status);
            }
        } catch (err) {
            console.log('Sensix API error', err);
        }
    }

    async function sendCandidateApplicationToMondayBoard({
        firstName,
        lastName,
        email,
        phone,
        linkedIn,
        websites,
        startDate,
        desiredIncome,
        references,
        finalMessage
    }: FormValues) {
        try {
            setError(false);

            const position =
                // eslint-disable-next-line no-prototype-builtins
                job && jobsToMondayPositions.hasOwnProperty(job)
                    ? (jobsToMondayPositions as any)[job]
                    : 'Other'; // needs to be a value that already exists in the Monday Position column

            const columnValues = {
                email: { email, text: email },
                phone: phone,
                // Position -> dup__of_position9
                dup__of_position9: position,
                // application date -> date4
                date4: TODAY_DATE_STRING,
                // LinkedIn -> link7
                link7: { url: linkedIn, text: linkedIn },
                web_site__portfolio__blog: websites.join('\n'),
                desired_pay: desiredIncome,
                date: startDate,
                references__name__company__and_contact_info_3: references,
                // Candidate message -> long_text
                long_text: finalMessage,
                // source -> status3
                status3: 'Website'
            };

            const createResponse = await fetch(api.crm.endpoint, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: CREATE_APPLICATION_MUTATION,
                    resource: 'candidates',
                    variables: {
                        name: `${firstName} ${lastName}`,
                        columnValues: JSON.stringify(columnValues) // this needs to be a JSON string according to Monday documentation
                    }
                })
            });
            const responseAsJSON: CreateApplicationResponse = await createResponse.json();

            if (createResponse.status !== 200) {
                throw responseAsJSON;
            }

            const itemID = responseAsJSON.create_item.id;

            if (itemID && cvFiles.length > 0) {
                const uploadCVFormData = new FormData();
                uploadCVFormData.append('query', UPLOAD_CV_MUTATION);
                uploadCVFormData.append('variables', JSON.stringify({ itemId: parseInt(itemID) }));
                uploadCVFormData.append('map', JSON.stringify({ file: 'variables.file' }));
                uploadCVFormData.append('file', cvFiles[0]);
                const uploadCVResponse = await fetch(api.crm.endpointFiles, {
                    method: 'post',
                    body: uploadCVFormData
                });

                if (uploadCVResponse.status !== 200) {
                    throw await uploadCVResponse.json();
                }
            }

            setFinishedWithSuccess(true);
        } catch (err) {
            console.log('Monday GQL error', err);
            setError(true);
        }
    }

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        touched,
        values,
        setFieldValue,
        resetForm
    } = useFormik<FormValues>({
        initialValues: {
            bait: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            // work section
            linkedIn: '',
            websites: [''],
            startDate: '',
            desiredIncome: '',
            references: '',
            // Final message
            finalMessage: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!values.bait) {
                setLoading(true);
                await Promise.all([
                    sendCandidateApplicationToSensixAPI(values),
                    sendCandidateApplicationToMondayBoard(values)
                ]);
                setLoading(false);
            }
        }
    });

    function handleAddWebsite() {
        setFieldValue('websites', [...values.websites, '']);
    }

    function handleWebsitesChange(index: number, input: string) {
        const newWebsites = [...values.websites];
        newWebsites[index] = input;
        setFieldValue('websites', newWebsites);
    }

    function handleClose() {
        resetForm();
        onClose();
    }

    return (
        <BaseModal {...props} fullScreen onClose={onClose}>
            <Container>
                <ModalHeader>
                    <Icons.SensidevLogo />
                    <CloseIcon onClick={handleClose} />
                </ModalHeader>
                {finishedWithSuccess ? (
                    <AppliedSuccessfully>
                        <h4>{successInformation.applied}</h4>
                        <h1>{title}</h1>
                        <p>{successInformation.thanks}</p>
                        <SeeOtherJobsButton to="/careers">
                            {successInformation.otherJobs}
                        </SeeOtherJobsButton>
                        <Link to="/about">{successInformation.readMore}</Link>
                    </AppliedSuccessfully>
                ) : (
                    <Content>
                        <ContentHeader>
                            <ContentHeaderDescription>{description}</ContentHeaderDescription>
                            <ContentHeaderTitle>{title}</ContentHeaderTitle>
                        </ContentHeader>
                        <PersonalInformation>
                            <PersonalInformationTitle>
                                {personalInformation.title}
                            </PersonalInformationTitle>
                            <Name>
                                <FirstName>
                                    <TextInput
                                        disabled={finishedWithSuccess}
                                        error={touched.firstName ? errors.firstName : undefined}
                                        touched={touched.firstName}
                                        label={personalInformation.form.firstName.label}
                                        name="firstName"
                                        placeholder={personalInformation.form.firstName.placeholder}
                                        type="text"
                                        value={values.firstName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </FirstName>
                                <LastName>
                                    <TextInput
                                        disabled={finishedWithSuccess}
                                        error={touched.lastName ? errors.lastName : undefined}
                                        touched={touched.lastName}
                                        label={personalInformation.form.lastName.label}
                                        name="lastName"
                                        placeholder={personalInformation.form.lastName.placeholder}
                                        type="text"
                                        value={values.lastName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </LastName>
                            </Name>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.email ? errors.email : undefined}
                                    touched={touched.email}
                                    label={personalInformation.form.email.label}
                                    name="email"
                                    placeholder={personalInformation.form.email.placeholder}
                                    type="email"
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.phone ? errors.phone : undefined}
                                    touched={touched.phone}
                                    label={personalInformation.form.phone.label}
                                    name="phone"
                                    placeholder={personalInformation.form.phone.placeholder}
                                    type="text"
                                    value={values.phone}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                        </PersonalInformation>
                        <WorkInformation>
                            <WorkInformationTitle>{workInformation.title}</WorkInformationTitle>
                            <Upload>
                                <UploadLabel>{workInformation.form.upload.label}</UploadLabel>
                                <DropZone
                                    files={cvFiles}
                                    setFiles={setCVFiles}
                                    acceptTypes={['application/pdf']}
                                    maxFiles={1}
                                />
                            </Upload>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.linkedIn ? errors.linkedIn : undefined}
                                    touched={touched.linkedIn}
                                    label={workInformation.form.linkedIn.label}
                                    name="linkedIn"
                                    placeholder={workInformation.form.linkedIn.placeholder}
                                    type="text"
                                    value={values.linkedIn}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <Work>
                                <WorkDescription>
                                    {workInformation.form.websites.label}
                                </WorkDescription>
                                {values.websites.map((website, index) => (
                                    <TextInput
                                        disabled={finishedWithSuccess}
                                        key={`website-${index}`}
                                        error={
                                            errors.websites && errors.websites[index]
                                                ? errors.websites[index]
                                                : undefined
                                        }
                                        touched={values.websites[index] !== ''}
                                        isErrorDetected={
                                            errors.websites ? errors.websites[index] : undefined
                                        }
                                        name="link"
                                        placeholder={workInformation.form.websites.placeholder}
                                        type="text"
                                        value={website}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                            handleWebsitesChange(index, e.target.value)
                                        }
                                    />
                                ))}
                                {values.websites.length < 5 && (
                                    <WorkAddLink onClick={handleAddWebsite}>
                                        {workInformation.addLink}
                                    </WorkAddLink>
                                )}
                            </Work>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.startDate ? errors.startDate : undefined}
                                    touched={touched.startDate}
                                    label={workInformation.form.startDate.label}
                                    name="startDate"
                                    placeholder={workInformation.form.startDate.placeholder}
                                    type="date"
                                    value={values.startDate}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    min={TODAY_DATE_STRING}
                                />
                            </InputContainer>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.desiredIncome ? errors.desiredIncome : undefined}
                                    touched={touched.desiredIncome}
                                    label={workInformation.form.desiredIncome.label}
                                    name="desiredIncome"
                                    placeholder={workInformation.form.desiredIncome.placeholder}
                                    type="text"
                                    value={values.desiredIncome}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <TextInput
                                    disabled={finishedWithSuccess}
                                    error={touched.references ? errors.references : undefined}
                                    touched={touched.references}
                                    label={workInformation.form.references.label}
                                    name="references"
                                    placeholder={workInformation.form.references.placeholder}
                                    type="text"
                                    value={values.references}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                            <InputContainer>
                                <AutoresizeInput
                                    disabled={finishedWithSuccess}
                                    error={touched.finalMessage ? errors.finalMessage : undefined}
                                    label={workInformation.form.finalMessage.label}
                                    name="finalMessage"
                                    placeholder={workInformation.form.finalMessage.placeholder}
                                    value={values.finalMessage}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </InputContainer>
                        </WorkInformation>
                        <BaitInput
                            name="bait"
                            required
                            tabIndex={-1}
                            type="hidden"
                            value={values.bait}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <Disclaimer>
                            {disclaimer}&nbsp;
                            <RichText>
                                <ExternalLink rel="noopener" target="_blank" href="/privacy/">
                                    {privacyPolicy}
                                </ExternalLink>
                            </RichText>
                        </Disclaimer>
                        <PrimaryButton
                            name={analytics.careersModalSubmitMyApplication}
                            isLoading={loading}
                            disabled={
                                !isValid || cvFiles.length === 0 || finishedWithSuccess || loading
                            }
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {cta}
                        </PrimaryButton>
                        {error && <UIError>{api.contactError}</UIError>}
                    </Content>
                )}
            </Container>
        </BaseModal>
    );
}
