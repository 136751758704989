import styled from 'styled-components';
import { Link as DefaultLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { TextInput as BaseTextInput } from '../TextInput';
import { AutoresizeInput as ResizableInput } from '../AutoresizeInput';
import { PrimaryButton as Button } from '../Button';
import { Colors, MediaQueries, Icons } from 'environment';
import {
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE,
    DESKTOP_GRID_SIZE
} from 'consts';

interface Props {
    isErrorDetected?: string | undefined;
    touched?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;

export const TextInput = styled(BaseTextInput)<Props>`
    margin-bottom: 2.5rem;

    label {
        font-size: 1.2rem;
        height: 1.9rem;
        margin-bottom: 0.5rem;
        color: ${Colors.violet};
    }

    input[type='date'] {
        color: ${({ value }) => value === '' && Colors.lavender};

        ::-webkit-calendar-picker-indicator {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    input,
    textarea {
        border-bottom: ${({ error, touched }) =>
            error && touched
                ? `0.1rem ${Colors.danger} solid`
                : !error && touched
                ? `0.1rem ${Colors.green} solid`
                : `0.1rem ${Colors.brownGrey} solid`};

        :focus,
        :active,
        :hover {
            border-bottom: ${({ error, touched }) =>
                error && touched
                    ? `0.1rem ${Colors.danger} solid`
                    : !error && touched
                    ? `0.1rem ${Colors.green} solid`
                    : `0.1rem ${Colors.brownGrey} solid`};
        }
    }

    ::placeholder {
        color: ${Colors.lavender};
        padding: 1.6rem 2rem;
    }
`;

export const AutoresizeInput = styled(ResizableInput).attrs({ style: { minHeight: 65 } })`
    label {
        font-size: 1.2rem;
        height: 1.9rem;
        margin-bottom: 0.5rem;
        color: ${Colors.violet};
    }

    textarea {
        min-height: 6rem;
    }
`;

export const Disclaimer = styled.span`
    display: inline-block;
    margin-bottom: 4rem;
    margin-top: 8rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
`;

export const Link = styled(DefaultLink)`
    font-size: 1.4rem;
    line-height: 2.2rem;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.magenta};
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
        border-color: ${Colors.magenta};
    }
`;

export const ExternalLink = styled(OutboundLink)`
    font-size: 1.4rem;
    line-height: 2.2rem;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.magenta};
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
        border-color: ${Colors.magenta};
    }
`;

export const PrimaryButton = styled(Button)`
    transition: background-color 0.2s, color 0.2s;

    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    width: 100%;
`;

export const CloseIcon = styled(Icons.Close)`
    cursor: pointer;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
        margin-top: 8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin: 6rem 0;
    }
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8rem;

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const ContentHeaderTitle = styled.h1`
    color: ${Colors.violet};

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
    }
`;

export const ContentHeaderDescription = styled.p`
    color: ${Colors.darkViolet};
    margin-bottom: 2rem;
`;

export const PersonalInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const PersonalInformationTitle = styled.h4`
    line-height: 3.8rem;
    color: ${Colors.lightGreen};
    margin-bottom: 4rem;
`;

export const Name = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media ${MediaQueries.phone} {
        flex-direction: column;
    }
`;

export const FirstName = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE / 2 - 1}rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 2rem;
    }
`;

export const LastName = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE / 2 - 1}rem;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
    }
`;

export const WorkInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 8rem;

    @media ${MediaQueries.phone} {
        margin-top: 4rem;
    }
`;

export const WorkInformationTitle = styled.h4`
    line-height: 3.8rem;
    color: ${Colors.lightGreen};
    margin-bottom: 4rem;
`;

export const Upload = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .input-placeholder {
        font-family: 'Rubik';
        color: ${Colors.lavender};
    }
`;

export const UploadLabel = styled.span`
    color: ${Colors.violet};
    margin-bottom: 2rem;

    &:focus {
        outline-color: ${Colors.green};
    }
`;

export const Work = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
    }
`;

export const WorkDescription = styled.span`
    color: ${Colors.violet};

    @media ${MediaQueries.desktop} {
        margin-bottom: 0.5rem;
    }
`;

export const WorkAddLink = styled.a`
    color: ${Colors.magenta};
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 4rem;

    &:hover {
        border-color: ${Colors.magenta};
    }
`;

export const BaitInput = styled(BaseTextInput)`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
`;

export const Error = styled.p`
    color: ${Colors.danger};
    font-size: 2rem;
    line-height: 2.7rem;
    font-weight: 600;
    margin-top: 2rem;
`;

export const AppliedSuccessfully = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 10rem);
    text-align: center;
    width: 100%;

    h4 {
        color: ${Colors.violet};
        margin-bottom: 1rem;

        @media ${MediaQueries.phone} {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }

    h1 {
        color: ${Colors.violet};
        margin-bottom: 1rem;

        @media ${MediaQueries.phone} {
            font-size: 3.6rem;
            line-height: 5rem;
            margin-bottom: 2rem;
        }
    }

    p {
        color: #624471;
    }

    a {
        font-size: 1.8rem;
    }
`;

export const SeeOtherJobsButton = styled(Button)`
    margin-top: 8rem;
    margin-bottom: 4rem;
    width: 100%;

    @media ${MediaQueries.phone} {
        margin-top: 4rem;
        margin-bottom: 2rem;

        a {
            width: 100%;
        }
    }

    @media ${MediaQueries.tablet} {
        width: 30rem;
    }

    @media ${MediaQueries.desktop} {
        width: 38rem;
    }
`;
