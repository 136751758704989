import { useLayoutEffect, useRef } from 'react';
import { WINDOW_EXISTS } from 'consts';

interface Position {
    x: number;
    y: number;
}

type Effect = ({ previous, current }: { previous: Position; current: Position }) => void;

export function useScrollPosition(effect: Effect, deps: React.DependencyList = [], wait = 100) {
    const position = useRef({ x: 0, y: 0 });
    let timeout: number | null = null;

    function callback() {
        if (WINDOW_EXISTS) {
            const currentPosition = { x: window.scrollX, y: window.scrollY };
            effect({ previous: position.current, current: currentPosition });
            position.current = currentPosition;
            timeout = null;
        }
    }

    useLayoutEffect(() => {
        function handleScroll() {
            if (wait) {
                if (timeout === null) {
                    timeout = setTimeout(callback, wait);
                }
            } else {
                callback();
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, deps);
}
