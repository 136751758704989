import React, { useState } from 'react';
import { navigate } from 'gatsby';

import content from 'data/cookie-modal.json';

import { BaseModal, BaseModalProps } from './BaseModal';
import { Switch } from '../Switch';
import { Button, Container, Description, Row, Title, ButtonsRow } from './CookieModal.style';

interface Props extends BaseModalProps {
    onUpdate: (on: boolean) => void;
}

export function CookieModal({ onClose, onUpdate, ...props }: Props) {
    const [on, setOn] = useState(true);

    function handleChange(on: boolean) {
        setOn(on);
    }

    function handleLearnMore() {
        navigate('/cookies');
        onClose();
    }

    function handleUpdate() {
        onUpdate(on);
        onClose();
    }

    return (
        <BaseModal {...props} onClose={onClose}>
            <Container>
                <img src="/images/cookie.svg" alt="Cookie icon" />
                <Row>
                    <Title>{content.settings.title}</Title>
                    <Switch id="cookie-switch" on={on} onChange={handleChange} />
                </Row>
                <Description>{content.settings.description}</Description>
                <ButtonsRow>
                    <Button transparent onClick={handleLearnMore}>
                        {content.settings.cta}
                    </Button>
                    <Button onClick={handleUpdate}>{content.settings.label}</Button>
                </ButtonsRow>
            </Container>
        </BaseModal>
    );
}
