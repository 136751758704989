import React, { useState } from 'react';

import { Icons, Colors } from 'environment';

import { SliderItem } from './SliderItem';
import { Container, Content, Controls } from './Slider.style';

export interface SliderProps {
    activeIndex?: number;
    index?: number;
    onMeasure?: (height: number) => void;
}

interface Props {
    children: React.ReactElement<SliderProps>[];
}

export function Slider({ children }: Props) {
    const [height, setHeight] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const previousDisabled = activeIndex === 0;
    const nextDisabled = activeIndex === React.Children.count(children) - 1;

    function handleMeasure(h: number) {
        if (h > height) {
            setHeight(h);
        }
    }

    function handlePreviousClick() {
        if (!previousDisabled) {
            setActiveIndex((i) => (i -= 1));
        }
    }

    function handleNextClick() {
        if (!nextDisabled) {
            setActiveIndex((i) => (i += 1));
        }
    }

    const enhancedChildren = React.Children.map(children, (child, index) =>
        React.cloneElement<SliderProps>(child, {
            activeIndex,
            index,
            onMeasure: handleMeasure
        })
    );

    return (
        <Container>
            <Content style={{ height: height || 'auto' }}>{enhancedChildren}</Content>
            <Controls>
                <Icons.ArrowLeft
                    fill={previousDisabled ? Colors.lavender : Colors.violet}
                    style={{ cursor: previousDisabled ? 'not-allowed' : 'pointer' }}
                    onClick={handlePreviousClick}
                />
                <Icons.ArrowRight
                    fill={nextDisabled ? Colors.lavender : Colors.violet}
                    style={{ cursor: nextDisabled ? 'not-allowed' : 'pointer', marginLeft: '2rem' }}
                    onClick={handleNextClick}
                />
            </Controls>
        </Container>
    );
}

Slider.Item = SliderItem;
