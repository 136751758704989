import React from 'react';
import { useDropzone } from 'react-dropzone';

import { Icons, MediaQueries } from 'environment/theme';
import { useMediaQuery } from 'hooks';

import {
    Browse,
    Container,
    Content,
    DragZoneMessage,
    File,
    FilesContainer,
    Icon
} from './DropZone.style';

interface Props {
    files: File[];
    setFiles: (files: File[]) => void;
    acceptTypes: string[];
    maxFiles?: number;
}

export function DropZone({ files, setFiles, acceptTypes, maxFiles = 10 }: Props) {
    const onDropHandler = (acceptedFiles: File[]) => {
        setFiles([...files, ...acceptedFiles]);
    };

    const acceptValue = acceptTypes.reduce((acc, current) => {
        acc[current] = [];
        return acc;
    }, {} as { [key: string]: string[] });

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: acceptValue,
        onDrop: onDropHandler,
        maxFiles: 1 // allow select only one file at a time
    });

    const isPhone = useMediaQuery(MediaQueries.phone);

    function deleteFile(name: string) {
        setFiles(files.filter((file) => file.name !== name));
    }

    return (
        <Container>
            {files.length < maxFiles && (
                <Content {...getRootProps({ isDragAccept, isDragActive, isDragReject })}>
                    <input {...getInputProps()} />
                    {!isDragActive &&
                        !isDragAccept &&
                        !isDragReject &&
                        (isPhone ? (
                            <Browse>Browse files</Browse>
                        ) : (
                            <DragZoneMessage>
                                Drag & drop your files here or <Browse>Browse</Browse>
                            </DragZoneMessage>
                        ))}
                    {isDragAccept && isDragActive && (
                        <DragZoneMessage>Drop the file</DragZoneMessage>
                    )}
                    {isDragReject && isDragActive && (
                        <DragZoneMessage>File type not accepted</DragZoneMessage>
                    )}
                </Content>
            )}
            <FilesContainer>
                {files.map((file) => (
                    <File key={file.name}>
                        <p>{file.name}</p>
                        <Icon onClick={() => deleteFile(file.name)}>
                            <Icons.DeleteFile />
                        </Icon>
                    </File>
                ))}
            </FilesContainer>
        </Container>
    );
}
