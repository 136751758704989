import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useFormik } from 'formik';
import * as yup from 'yup';

import footer from 'data/footer.json';
import social from 'data/social.json';
import contact from 'data/contact.json';
import api from 'data/api.json';
import analytics from 'data/analytics.json';
import { Colors, Icons } from 'environment';
import { sendEmail } from 'helpers';

import {
    Address,
    Column,
    Container,
    Content,
    Email,
    Link,
    ExternalLink,
    Newsletter,
    Sensidev,
    Title,
    Item,
    NewsletterDescription,
    NewsletterTerms,
    NewsletterInput,
    NewsletterLink,
    SocialMedia,
    SocialMediaCredits,
    Error,
    Success,
    SubscribeButton,
    NewsletterTitle,
    EmailRow,
    AddressRow,
    SocialContainer,
    SocialMediaContainer
} from './Footer.style';

const validationSchema = yup.object().shape({
    email: yup.string().email(footer.newsletter.invalid).required(footer.newsletter.required)
});

export function Footer() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        touched,
        validateForm,
        values
    } = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            if (values.email) {
                const { email } = values;

                try {
                    setSuccess(false);
                    setError('');
                    setLoading(true);

                    const res = await addToMailchimp(email);

                    if (res.result === 'success') {
                        setSuccess(true);
                    } else if (res.result === 'error') {
                        if (res.msg.includes('is already subscribed')) {
                            setError(api.mailChimp.alreadySubscribed);
                        }
                        if (res.msg.includes('too many recent signup requests')) {
                            setError(api.mailChimp.tooManyAttempts);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (error.message) {
                        setError(api.internalError);
                    }
                } finally {
                    setLoading(false);
                }
            }
        }
    });

    function handleClick() {
        if (isValid) {
            handleSubmit();
        } else {
            validateForm();
        }
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (error.length > 0) {
            setError('');
            handleChange(e);
        } else {
            handleChange(e);
        }
    }

    return (
        <Container>
            <Content>
                <Sensidev>
                    <Icons.FooterLogo />
                    <AddressRow>
                        <Icons.LocationIcon />
                        <Address href={social.googleMapsURL} target="_blank">
                            <p>{contact.location.address}</p>
                        </Address>
                    </AddressRow>
                    <EmailRow>
                        <Icons.EmailIcon />
                        <Email
                            onClick={() =>
                                sendEmail(contact.email.encoded, contact.email.emailSubject)
                            }
                        >
                            <p>{contact.email.displayText}</p>
                        </Email>
                    </EmailRow>
                </Sensidev>
                {footer.columns.map((column, index) => (
                    <Column key={`column-${index}`}>
                        <Title>{column.title}</Title>
                        {column.items.map((item, i) => {
                            if (item.to) {
                                if (item.label === 'Privacy Policy') {
                                    return (
                                        <ExternalLink
                                            key={`column-${index}-item-${i}`}
                                            rel="noopener"
                                            target="_blank"
                                            href={item.to}
                                        >
                                            {item.label}
                                        </ExternalLink>
                                    );
                                }
                                return (
                                    <Link
                                        rel={item.label === 'Privacy Policy' ? 'noopener' : ''}
                                        target={item.label === 'Privacy Policy' ? '_blank' : ''}
                                        key={`column-${index}-item-${i}`}
                                        activeClassName="active-link"
                                        to={item.to}
                                    >
                                        {item.label}
                                    </Link>
                                );
                            }
                            return <Item key={`column-${index}-item-${i}`}>{item.label}</Item>;
                        })}
                    </Column>
                ))}
                <Newsletter>
                    <NewsletterTitle>{footer.newsletter.title}</NewsletterTitle>
                    <NewsletterDescription>{footer.newsletter.description}</NewsletterDescription>
                    <NewsletterInput
                        disabled={success}
                        error={touched.email ? errors.email : undefined}
                        name="email"
                        placeholder={footer.newsletter.placeholder}
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        onSubmit={handleClick}
                    />
                    <NewsletterTerms>
                        <NewsletterDescription>{footer.newsletter.terms}</NewsletterDescription>
                        <NewsletterLink activeClassName="active-link" to="/privacy">
                            {footer.newsletter.privacyPolicy}
                        </NewsletterLink>
                    </NewsletterTerms>
                    {error && <Error>{error}</Error>}
                    {success && <Success>{api.mailChimp.subscribeSuccess}</Success>}
                    <SubscribeButton
                        isLoading={loading}
                        loaderColor={Colors.violet}
                        disabled={!isValid || success}
                        name={analytics.footerNewNewsletterSignUpSubscribe}
                        margin={{ top: 20 }}
                        onClick={handleClick}
                    >
                        {footer.newsletter.buttonLabel}
                    </SubscribeButton>
                </Newsletter>
            </Content>
            <SocialContainer>
                <SocialMediaCredits>
                    {footer.credits.replace('[YEAR]', new Date().getFullYear().toString())}
                </SocialMediaCredits>
                <SocialMedia>
                    <SocialMediaContainer href={social.facebookProfileLink} target="_blank">
                        <Icons.Facebook height="4rem" width="4rem" />
                    </SocialMediaContainer>
                    <SocialMediaContainer href={social.instagramProfileLink} target="_blank">
                        <Icons.Instagram height="4rem" width="4rem" />
                    </SocialMediaContainer>
                    <SocialMediaContainer href={social.linkedInProfileLink} target="_blank">
                        <Icons.LinkedIn height="4rem" width="4rem" />
                    </SocialMediaContainer>
                </SocialMedia>
            </SocialContainer>
        </Container>
    );
}
