import { graphql, useStaticQuery } from 'gatsby';

import { CustomMarkdownQueryData } from 'types';

export function usePrivacyPolicyMarkdown() {
    const { allMarkdownRemark: data } = useStaticQuery<CustomMarkdownQueryData>(
        PRIVACY_POLICY_MARKDOWN
    );
    return data && data.edges ? data.edges[0].node.html : '';
}

const PRIVACY_POLICY_MARKDOWN = graphql`
    query {
        allMarkdownRemark(filter: { frontmatter: { entity: { eq: "privacy" } } }) {
            edges {
                node {
                    html
                }
            }
        }
    }
`;
