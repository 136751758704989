import styled from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    background-color: ${Colors.dirtyWhite};
    border-radius: 0.8rem;
    height: 9.7rem;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const DragZoneMessage = styled.p`
    color: ${Colors.lavender};
    padding: 3.9rem 8rem;
    text-align: center;
    font-size: 1.8rem;
`;

export const Browse = styled.a`
    color: ${Colors.magenta};
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: normal;
`;

export const File = styled.div`
    color: ${Colors.violet};
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Icon = styled.div`
    background-color: ${Colors.lavender};
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    align-items: center;
    margin-left: 2rem;
    cursor: pointer;
`;
