import React from 'react';

import { Container, Input, Error, Textarea } from './TextInput.style';

interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    className?: string;
    label?: string;
    disabled?: boolean;
    rows?: number;
    error?: string;
    touched?: boolean;
    multiline?: boolean;
    onSubmit?: () => void;
}

export function TextInput({
    className,
    label,
    name,
    error,
    touched,
    rows,
    multiline = false,
    onSubmit,
    ...props
}: Props) {
    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.key === 'Enter' && onSubmit) {
            event.preventDefault();
            event.stopPropagation();
            onSubmit();
        }
    }

    return (
        <Container className={className}>
            {label && <label htmlFor={name}>{label}</label>}
            {multiline ? (
                <Textarea
                    {...props}
                    id={name}
                    rows={rows}
                    name={name}
                    error={!!error}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                <Input {...props} name={name} error={!!error} onKeyDown={handleKeyDown} />
            )}
            {error && <Error>{error}</Error>}
        </Container>
    );
}
