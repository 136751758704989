import React from 'react';

import { Container, Error, AutosizeTextarea } from './AutoresizeInput.style';

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    className?: string;
    label: string;
    error?: string;
    onSubmit?: () => void;
}

export function AutoresizeInput({ className, label, name, error, onSubmit, ...props }: Props) {
    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.key === 'Enter' && onSubmit) {
            event.preventDefault();
            event.stopPropagation();
            onSubmit();
        }
    }

    return (
        <Container className={className}>
            {label && <label htmlFor={name}>{label}</label>}
            <AutosizeTextarea
                {...props}
                id={name}
                name={name}
                error={error ? 1 : 0}
                onKeyDown={handleKeyDown}
            />
            {error && <Error>{error}</Error>}
        </Container>
    );
}
