import { Link as BaseLink } from 'gatsby';
import styled from 'styled-components';

import { MediaQueries, Colors, Icons } from 'environment';

export const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-color: ${Colors.violet};
    padding: 0 2.4rem;
    transition: transform 0.5s ease-in-out;

    @media ${MediaQueries.tablet} {
        padding: 0 7.4rem;
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 3.6rem;

    @media ${MediaQueries.phone} {
        padding-top: 12rem;
    }

    @media ${MediaQueries.tablet} {
        padding-top: 18rem;
        padding-right: 4rem;
        padding-left: 4rem;
    }

    a:not(:last-child) {
        margin-bottom: 2rem;
    }
`;

export const Link = styled(BaseLink)`
    width: 100%;
    font-size: 2.4rem;
    line-height: 3.8rem;
    color: ${Colors.white};
    width: fit-content;
    text-transform: none;
    position: relative;

    &.active-link {
        color: ${Colors.orange};
        font-weight: 500;
    }

    &.active-link::before {
        content: '';
        height: 0.8rem;
        width: 0.8rem;
        position: absolute;
        left: -4rem;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${Colors.orange};
        border-radius: 50%;
    }

    @media ${MediaQueries.tablet} {
        font-size: 3.6rem;
        line-height: 5rem;
    }
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0rem 1.8rem;
`;

export const CloseIcon = styled(Icons.Close)`
    position: absolute;
    height: 3rem;

    & > path {
        fill: ${Colors.white};
    }

    @media ${MediaQueries.phone} {
        top: 3.5rem;
        right: 2.4rem;
    }

    @media ${MediaQueries.tablet} {
        top: 3.5rem;
        right: 8rem;
    }
`;

export const SensidevImage = styled.img`
    height: 4rem;
    position: absolute;
    top: 3rem;

    @media ${MediaQueries.phone} {
        left: 2rem;
    }

    @media ${MediaQueries.tablet} {
        left: 7.4rem;
    }
`;
