import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { DEBOUNCE_TIME } from 'consts';

interface DebounceSettings {
    leading?: boolean;
    maxWait?: number;
    trailing?: boolean;
}

interface DebouncedFunc<T extends (...args: any[]) => any> {
    (...args: Parameters<T>): ReturnType<T> | undefined;
    cancel(): void;
    flush(): ReturnType<T> | undefined;
}

function useDebouncedCallback<T extends (...args: any[]) => any>(
    callback: T,
    delay: number,
    options?: DebounceSettings
): DebouncedFunc<T> {
    return useCallback(debounce(callback, delay, options), [callback, delay, options]);
}

export function useDebounce<T>(
    value: T,
    delay: number = DEBOUNCE_TIME,
    options?: DebounceSettings
): T {
    const previousValue = useRef(value);
    const [current, setCurrent] = useState(value);
    const debouncedCallback = useDebouncedCallback((value: T) => setCurrent(value), delay, options);

    useEffect(() => {
        if (value !== previousValue.current) {
            debouncedCallback(value);
            previousValue.current = value;
            return debouncedCallback.cancel;
        }
    }, [value]);

    return current;
}
