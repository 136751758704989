import React from 'react';
import Helmet from 'react-helmet';

import generalConfiguration from 'data/general.json';
import { useQueryContext } from 'hooks';

interface Props {
    title?: string;
    description?: string;
    meta?: HTMLMetaElement[];
    lang?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
}

export function SEO({
    description,
    lang,
    meta = [],
    ogDescription,
    ogImage,
    ogTitle,
    title
}: Props) {
    const {
        site: { siteMetadata }
    } = useQueryContext();

    const {
        title: defaultTitle,
        author,
        description: defaultDescription,
        language: defaultLanguage
    } = siteMetadata;

    const metaImage = `${generalConfiguration.siteUrl}${ogImage}`;
    const language = lang || defaultLanguage;
    const computedLang = lang || generalConfiguration.siteLanguage;

    return (
        <Helmet
            htmlAttributes={{ language, lang: computedLang }}
            title={title || defaultTitle}
            titleTemplate={title || defaultTitle}
            meta={[
                {
                    name: `author`,
                    content: author
                },
                {
                    name: `description`,
                    content: description || defaultDescription
                },
                {
                    property: `og:title`,
                    content: ogTitle
                },
                {
                    property: `og:description`,
                    content: ogDescription || defaultDescription
                },
                {
                    property: `og:image`,
                    content: metaImage
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                // If this is not set, it will use URL of the page, good enough for us!
                // Else we need to go and be specific for each page, but no point ATM to do that.
                // More details here: https://developers.facebook.com/docs/sharing/webmasters/getting-started/versioned-link/
                // {
                //     property: `og:url`,
                //     content: generalConfiguration.siteUrl
                // },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: author
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: description || defaultDescription
                }
            ].concat(meta)}
        />
    );
}
