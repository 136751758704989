import React, { useEffect, useState } from 'react';

import { Colors } from 'environment';
import { useSpring } from 'hooks';

import { AnimatedThumb, AnimatedTrack, Input, Label } from './Switch.style';

interface Props {
    id: string;
    on?: boolean;
    onChange?: (value: boolean) => void;
}

export function Switch({ id, on = false, onChange }: Props) {
    const [value, setValue] = useState(on);
    const { backgroundColor, left, top } = useSpring({
        backgroundColor: value ? Colors.green : Colors.brownGrey,
        left: value ? '3.2rem' : '0.2rem',
        top: '0.2rem'
    });

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    function handleChange() {
        setValue((state) => !state);
    }

    return (
        <AnimatedTrack style={{ backgroundColor }}>
            <Label htmlFor={id}>
                <AnimatedThumb style={{ left, top }} />
            </Label>
            <Input id={id} type="checkbox" onChange={handleChange} />
        </AnimatedTrack>
    );
}
