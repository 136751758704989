import styled from 'styled-components';
import { MediaQueries } from 'environment';

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const Content = styled.div`
    width: 100%;
`;

export const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4rem;

    @media ${MediaQueries.desktop} {
        margin-top: 4rem;
    }
`;
