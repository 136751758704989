export const Colors = {
    white: '#fff',
    magenta: '#d11380',
    violet: '#4d345b',
    darkViolet: '#573b66',
    lightGray: '#f4f4f4',
    green: '#3fb3a2',
    new: {
        lightGray: '#edebef',
        lightGrayHover: '#d3ccd6',
        green: '#36988a',
        greenDisabled: '#79cabe',
        greenHover: '#2c7d71',
        lightGreenDisabled: '#ecf7f6',
        keplerTurquoiseSwitchButton: 'rgba(207, 236, 232, 0.6)'
    },
    lightGreen: '#48bbac',
    lavender: '#c1b6c8',
    brownGrey: '#828282',
    orange: '#F48026',
    danger: '#d13463',
    dirtyWhite: '#f8f8f8',
    indigo: '#243746',
    modalOverlay: 'rgba(77, 52, 91, 0.65)',
    transparent: 'rgba(0,0,0,0)'
};
