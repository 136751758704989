import styled from 'styled-components';
import { animated } from 'react-spring';

import { Colors } from 'environment';

const Track = styled.div`
    display: flex;
    align-items: center;
    height: 3rem;
    width: 6rem;
    border-radius: 2rem;
    overflow: hidden;
`;
export const AnimatedTrack = animated(Track);

const Thumb = styled.div`
    position: relative;
    height: 2.6rem;
    width: 2.6rem;
    background-color: ${Colors.white};
    border-radius: 50%;
`;
export const AnimatedThumb = animated(Thumb);

export const Label = styled.label`
    cursor: pointer;
    width: 100%;
    height: 100%;
`;

export const Input = styled.input`
    appearance: none;
`;
