import React, { useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { links } from 'data/header.json';
import { logo } from 'data/landing.json';
import { buttons } from 'data/general.json';
import { headerMobileToContactPage } from 'data/analytics.json';

import { Container, Content, Header, CloseIcon, Link, SensidevImage } from './MobileMenu.style';
import { PrimaryButton } from '../Button';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
}

export function MobileMenu({ isOpen, onClose }: Props) {
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        if (isOpen) setVisible(true);
        else {
            const id = setTimeout(() => setVisible(false), 300);
            return () => clearTimeout(id);
        }
    }, [isOpen]);

    return (
        <Container
            style={{
                opacity: visible ? 1 : 0,
                transform: isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-100vw, 0, 0)'
            }}
        >
            {isOpen && (
                <RemoveScroll>
                    <SensidevImage src={`/images/${logo.src}`} alt={logo.alt} />
                    <Header>
                        <CloseIcon onClick={onClose} />
                    </Header>
                    <Content>
                        <Link to="/" activeClassName="active-link" onClick={onClose}>
                            {links.home}
                        </Link>
                        <Link to="/about-us/" activeClassName="active-link" onClick={onClose}>
                            {links.about}
                        </Link>
                        <Link to="/services/" activeClassName="active-link" onClick={onClose}>
                            {links.services}
                        </Link>
                        <Link
                            partiallyActive
                            to="/portfolio/"
                            activeClassName="active-link"
                            onClick={onClose}
                        >
                            {links.portfolio}
                        </Link>
                        <Link
                            partiallyActive
                            to="/careers/"
                            activeClassName="active-link"
                            onClick={onClose}
                        >
                            {links.careers}
                        </Link>
                        <Link
                            partiallyActive
                            to="/blog/"
                            activeClassName="active-link"
                            onClick={onClose}
                        >
                            {links.blog}
                        </Link>
                        <PrimaryButton
                            to="/contact/"
                            name={headerMobileToContactPage}
                            onClick={onClose}
                        >
                            {buttons.contactCTA}
                        </PrimaryButton>
                    </Content>
                </RemoveScroll>
            )}
        </Container>
    );
}
