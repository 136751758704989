import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';

import { PrimaryButton } from '../Button';

export const Container = styled.div``;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${MediaQueries.phone} {
        align-items: flex-start;
    }
`;

export const ButtonsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${MediaQueries.phone} {
        flex-direction: column-reverse;
    }
`;

export const Title = styled.h4`
    color: ${Colors.violet};
    line-height: 3.8rem;

    @media ${MediaQueries.phone} and (max-width: 374px) {
        font-size: 2rem;
        line-height: 2.7rem;
    }

    @media ${MediaQueries.phone} {
        max-width: calc(100% - 6rem);
    }
`;

export const Description = styled.p`
    color: ${Colors.violet};
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
`;

export const Button = styled(PrimaryButton)`
    width: 100%;
    min-width: 24rem;

    :first-of-type {
        justify-content: flex-start;
        font-size: 1.8rem;
        padding: 0;

        @media ${MediaQueries.phone} {
            justify-content: center;
            height: 2.8rem;
        }
    }

    :last-of-type {
        @media ${MediaQueries.phone} {
            margin-bottom: 2rem;
        }
    }
`;
