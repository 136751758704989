import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

import { Colors } from 'environment';

interface TextareaProps {
    error: number;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const AutosizeTextarea = styled(TextareaAutosize)<TextareaProps>`
    display: flex;
    font-family: 'Rubik';
    font-size: 1.8rem;
    padding: 2rem;
    color: ${Colors.violet};
    background-color: transparent;
    border: none;
    border-bottom: ${({ error }) =>
        `0.1rem ${error === 1 ? Colors.danger : Colors.brownGrey} solid`};
    border-radius: 0;
    overflow: hidden;
    outline: none;
    transition: border-color 0.2s;

    ::placeholder {
        color: ${Colors.lavender};
    }

    :focus,
    :active {
        border-color: ${({ error }) => (error === 1 ? Colors.danger : Colors.violet)};
    }
`;

export const Error = styled.span`
    position: absolute;
    color: ${Colors.danger};
    bottom: -2.5rem;
`;
