import React from 'react';
import styled from 'styled-components';

import { Colors } from 'environment/theme';
import { BaseButton, BaseProps } from './BaseButton';

export function TertiaryButton(props: BaseProps) {
    return <Button {...props} backgroundColor={Colors.new.lightGray} color={Colors.violet} />;
}

const Button = styled(BaseButton)`
    :not(:disabled):hover {
        background-color: ${Colors.new.lightGrayHover};
    }
`;
