import styled from 'styled-components';
import BaseLink from 'gatsby-link';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';
import { TertiaryButton } from 'components/ui';
import { TextInput as BaseTextInput } from 'components/ui/TextInput';

export const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: ${Colors.white};
    position: relative;
    top: 10rem;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding-top: 13rem;
        padding-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        flex-wrap: wrap;
        max-width: ${TABLET_GRID_SIZE}rem;
        padding: 12rem 0;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        padding: 0 2rem;
        margin-top: 6rem;
    }
`;

export const Sensidev = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    svg {
        flex-shrink: 0;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
        margin-right: ${DESKTOP_GRID_COLUMN * 1 + DESKTOP_GRID_SPACING * 2}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING}rem;
        margin-right: 6rem;
    }

    @media ${MediaQueries.phone} {
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING}rem;
        margin-right: ${DESKTOP_GRID_SPACING}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: 12rem;
        :nth-child(2n) {
            margin-right: 4rem;
        }
    }

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
        align-items: center;
    }
`;

export const Newsletter = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
        margin-left: ${DESKTOP_GRID_COLUMN + DESKTOP_GRID_SPACING}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        margin-top: 7rem;
    }

    @media ${MediaQueries.phone} {
        align-items: flex-start;
        margin-top: 2rem;
    }
`;

export const NewsletterDescription = styled.span`
    color: ${Colors.violet};
`;

export const NewsletterTerms = styled.div`
    display: flex;
    margin-top: 4rem;
`;

export const NewsletterLink = styled(BaseLink)`
    font-size: 1.2rem;
    line-height: 1.9rem;
    font-weight: 400;
    color: ${Colors.violet};
    margin-left: 0.5rem;
    border-bottom: 0.2rem transparent solid;

    &:hover {
        border-color: ${Colors.magenta};
        color: ${Colors.magenta};
    }
`;

export const NewsletterInput = styled(BaseTextInput)`
    color: ${Colors.lavender};
    margin-top: 0.5rem;
    width: 100%;

    @media ${MediaQueries.tablet} {
        width: ${TABLET_GRID_SIZE}rem;
    }
`;

export const Title = styled.h4`
    color: ${Colors.violet};
    line-height: 3.8rem;
    margin-bottom: 4rem;
`;

export const NewsletterTitle = styled.h4`
    width: 100%;
    color: ${Colors.violet};
    line-height: 3.8rem;
    margin-bottom: 4rem;

    @media ${MediaQueries.phone} {
        text-align: center;
        margin-bottom: 2rem;
    }
`;

export const Link = styled(BaseLink)`
    color: ${Colors.violet};
    border-bottom: 0.2rem transparent solid;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
    margin-bottom: 1rem;
    transition: border-color 0.2s;
    width: fit-content;

    &.active-link {
        color: ${Colors.magenta};
    }

    &:hover {
        color: ${Colors.magenta};
        border-color: ${Colors.magenta};
    }
`;

export const ExternalLink = styled(OutboundLink)`
    color: ${Colors.violet};
    border-bottom: 0.2rem transparent solid;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
    margin-bottom: 1rem;
    transition: border-color 0.2s;
    width: fit-content;

    &.active-link {
        color: ${Colors.magenta};
    }

    &:hover {
        color: ${Colors.magenta};
        border-color: ${Colors.magenta};
    }
`;

export const Item = styled.p`
    color: ${Colors.violet};
    margin-bottom: 1rem;

    &:hover {
        color: ${Colors.magenta};
    }
`;

export const Address = styled(OutboundLink)`
    display: flex;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.violet};
    cursor: pointer;
    margin-bottom: 2rem;
    margin-left: 2rem;
    transition: border-color 0.2s;

    @media ${MediaQueries.desktop} {
        margin-right: 4rem;
    }
    &:hover {
        border-color: ${Colors.magenta};
        color: ${Colors.magenta};
    }
`;

export const AddressRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 4rem;
`;

export const Email = styled.div`
    display: flex;
    border-bottom: 0.2rem transparent solid;
    color: ${Colors.violet};
    cursor: pointer;
    margin-left: 2rem;
    transition: border-color 0.2s;
    vertical-align: middle;

    &:hover {
        border-color: ${Colors.magenta};
        color: ${Colors.magenta};
    }
`;

export const EmailRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const SocialMedia = styled.div`
    display: flex;

    @media ${MediaQueries.phone} {
        margin-top: 3rem;
    }
`;

export const SocialContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #fbfbfb;

    @media ${MediaQueries.desktop} {
        padding: 3rem calc((100% - ${DESKTOP_GRID_SIZE}rem) / 2);
    }

    @media ${MediaQueries.tablet} {
        padding: 3rem calc((100% - ${TABLET_GRID_SIZE}rem) / 2);
    }

    @media ${MediaQueries.phone} {
        justify-content: center;
        flex-direction: column-reverse;
        margin-top: 6rem;
        padding-bottom: 5rem;
    }
`;

export const SocialMediaCredits = styled.span`
    color: ${Colors.violet};

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
    }
`;

export const Error = styled.span`
    color: ${Colors.danger};
    font-weight: 600;
    margin-top: 2rem;
`;

export const Success = styled.span`
    color: ${Colors.green};
    font-weight: 600;
    margin-top: 2rem;
`;

export const SubscribeButton = styled(TertiaryButton)`
    align-self: flex-start;

    @media ${MediaQueries.phone} {
        align-self: center;
    }
`;
export const SocialMediaContainer = styled(OutboundLink)`
    display: flex;
    margin-left: 2rem;

    :first-child {
        margin-left: 0;
    }
`;
