import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';
import { ImageSharpNode, Nullable } from 'types';

interface Props {
    mobile?: Nullable<ImageSharpNode>;
    tablet?: Nullable<ImageSharpNode>;
    desktop?: Nullable<ImageSharpNode>;
    alt?: string;
    position?: string;
    className?: string;
}

export function FluidImage({ mobile, tablet, desktop, className, ...imageProps }: Props) {
    const isPhone = useMediaQuery(MediaQueries.phone);
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    return (
        <Container className={className}>
            {isPhone && mobile && <Image fluid={mobile.fluid} {...imageProps} />}
            {isTablet && tablet && <Image fluid={tablet.fluid} {...imageProps} />}
            {isDesktop && desktop && <Image fluid={desktop.fluid} {...imageProps} />}
        </Container>
    );
}

interface ImageProps {
    position?: string;
    height: number;
    width: number;
}

const Container = styled.div``;

const Image = styled(Img)<ImageProps>`
    pointer-events: none;
    user-select: none;
`;
