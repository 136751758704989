/**
 * An accessible button component.
 */
import { Link } from 'gatsby';
import React from 'react';
import { PulseLoader as Loader } from 'react-spinners';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import styled, { css } from 'styled-components/macro';

import { Colors } from 'environment';
import { WINDOW_EXISTS } from 'consts';

interface StyleProps {
    isLoading?: boolean;
    loaderColor?: string;
    width?: number | string;
    maxWidth?: number | string;
    margin?: {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
    };
    textTransform?: string;
    transparent?: boolean;
}

export interface BaseProps extends StyleProps {
    children: string;
    className?: string;
    disabled?: boolean;
    name?: string;
    to?: string;
    target?: string;
    type?: 'button' | 'submit';
    onClick?: () => void;
}

interface VariantProps {
    color: string;
    backgroundColor: string;
}

type Props = BaseProps & VariantProps;

export function BaseButton({
    className,
    name,
    children,
    isLoading = false,
    loaderColor = Colors.white,
    disabled = false,
    to,
    transparent = false,
    type = 'button',
    onClick,
    target = '_self',
    ...styleProps
}: Props) {
    function handleClick() {
        if (name) {
            WINDOW_EXISTS &&
                (window as any).gtag('event', 'click', {
                    event_category: 'button',
                    event_label: name
                });
            //  TODO: later take this one out as we confirm GA4 works correct!
            trackCustomEvent({
                category: 'button',
                action: 'click',
                label: name
            });
        }
        if (onClick) {
            onClick();
        }
    }

    if (to) {
        const isExternalLink = target === '_blank';
        // As per docs (https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#use-link-only-for-internal-links)
        // Link element should be used only for internal links, else a console warning will be triggered!
        return isExternalLink ? (
            <a href={to} target={target}>
                <Button
                    {...styleProps}
                    className={className}
                    aria-disabled={disabled}
                    isLoading={isLoading}
                    disabled={disabled}
                    transparent={transparent}
                    type={type}
                    role="button"
                    {...(onClick && { onClick })}
                >
                    {isLoading ? <Loader color={loaderColor} /> : children}
                </Button>
            </a>
        ) : (
            <Link to={to}>
                <Button
                    {...styleProps}
                    className={className}
                    aria-disabled={disabled}
                    isLoading={isLoading}
                    disabled={disabled}
                    transparent={transparent}
                    type={type}
                    role="button"
                    {...(onClick && { onClick })}
                >
                    {isLoading ? <Loader color={loaderColor} /> : children}
                </Button>
            </Link>
        );
    }

    return (
        <Button
            {...styleProps}
            className={className}
            isLoading={isLoading}
            aria-disabled={disabled}
            disabled={disabled}
            transparent={transparent}
            type={type}
            role="button"
            onClick={handleClick}
        >
            {isLoading ? <Loader color={loaderColor} /> : children}
        </Button>
    );
}

type ButtonProps = StyleProps & VariantProps;

const Button = styled.button<ButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: ${({ backgroundColor, transparent }) =>
        transparent
            ? '0.2rem transparent'
            : backgroundColor === 'transparent'
            ? '0.2rem solid white'
            : 'none'};
    border-radius: 3rem;
    background-color: ${({ backgroundColor, transparent }) =>
        transparent ? 'transparent' : backgroundColor};
    color: ${({ color, transparent }) => (transparent ? Colors.green : color)};
    height: 5rem;
    font-family: Rubik;
    font-size: ${({ transparent }) => (transparent ? '1.4rem' : '1.8rem')};
    font-weight: 500;
    width: ${({ width }) =>
        width !== undefined ? (typeof width === 'string' ? width : `${width}px`) : 'auto'};
    max-width: ${({ maxWidth }) =>
        maxWidth !== undefined
            ? typeof maxWidth === 'string'
                ? maxWidth
                : `${maxWidth}px`
            : 'none'};
    margin-top: ${({ margin }) => (margin?.top ? `${margin.top}px` : 0)};
    margin-bottom: ${({ margin }) => (margin?.bottom ? `${margin.bottom}px` : 0)};
    margin-left: ${({ margin }) => (margin?.left ? `${margin.left}px` : 0)};
    margin-right: ${({ margin }) => (margin?.right ? `${margin.right}px` : 0)};
    padding: 0 3rem;
    text-transform: ${({ textTransform, transparent }) =>
        !transparent && (textTransform || 'uppercase')};

    ${({ isLoading }) =>
        !isLoading &&
        css`
            :disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

            :not(:disabled) {
                cursor: pointer;
            }
        `}
`;
